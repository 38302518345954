import React from "react";
// import { Link } from "gatsby";

import SEO from "../components/seo";

const IndexPage = () => (
    <>
        <SEO title="Music" />
        <section>
            hello
        </section>
    </>
)

export default IndexPage;
